@import "../../styles/base.scss";

.leaderboards{
    padding-bottom: 11.628vw;
    .filter-container{
        margin-bottom: 3.721vw;
    }
    .clasament-container{
        display: flex;
        flex-direction: column;
        gap: 1.860vw;

        .clasament-item{
            display: block;
            padding: 2.791vw 3.721vw;
            background: rgba($color: $sw-white, $alpha: .2);
            display: flex;
            align-items: center;
            gap: 3.721vw;


            &:first-child{
                background-color: $sw-red;
            }
            &:nth-child(2){
                background-color: rgba($color: $sw-lime, $alpha: 0.5);
            }
            &:nth-child(3){
                background-color: rgba($color: $sw-lime, $alpha: 0.3);
            }



            .icon{
                width: auto;
                height: 5.581vw;
                &.first-place{
                    width: 5.581vw;
                    height: auto;
                } 
            }

            .position{ 
                font-size: 4.186vw;
                font-weight: 400;
                line-height: 4.884vw;
                color: $sw-white;
            }

            .user-image{
                display: block;
                width: 9.302vw;
                height: 9.302vw; 
                img{
                    width: 9.302vw;
                    height: 9.302vw;
                    border-radius: 50%; 
                    overflow: hidden;
                    object-fit: cover;
                }
            }

            .name{
                font-size: 3.721vw;
                font-weight: 600;
                line-height: 4.884vw;
                color: $sw-white;
            }

            .points{
                flex-grow: 1;
                text-align: right;
                font-size: 3.721vw;
                font-weight: 400;
                line-height: 4.884vw;
                color: $sw-white;
                span{
                    font-size: 2.558vw;
                }
            }

        }
    }

    ul.options-container{
        grid-template-columns: auto auto;
    }
    
    .banner-before-competition{

        img{
            width: 100%;
            height: auto;
        }
    }
}