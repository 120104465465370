@import "../../styles/base.scss";

.register-page{
    width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
    padding: 0 7.209vw 7.209vw;
    
    background: $sw-bg-gradient;

    .banner{
        margin: 0 -7.209vw;
        // padding-top: 3.721vw;
        // margin-bottom: -13.953vw;
        img{
            width: 100%;
            height: auto;
        }
    }

    .title{
        font-size: 2.791vw;
        font-weight: 600;
        line-height: 3.256vw;
        color: $sw-lime;
        text-transform: uppercase;
    }

    .user-details{
        .title{
            padding: 3.721vw 0;
        }
        .info-email{
            display: flex;
            gap: 1.860vw;
            font-size: 2.791vw;
            line-height: 3.721vw;
            color: $sw-white; 
            margin-bottom: 1.860vw;
            .label{
                font-weight: 600;
            }
            input{
                display: block; 
            }
        }
        .info-nickname{
            display: block;
            font-size: 2.791vw;
            line-height: 3.721vw;
            color: $sw-white; 
            margin-bottom: 3.721vw;
            .label{
                display: block;
                font-weight: 600;
                margin-bottom: 1.860vw;
            }
            input{
                display: block;
                width: 100%;
                border: none;
                border-radius: none;
                background-color: rgba($color: $sw-white, $alpha: 0.2);
                padding: 3.256vw;
                border: 2px solid #D1F669;
                font-size: 12px;
                line-height: 1;
                font-weight: 400;
                color: $sw-white;
            }
            .error{
                display: block;
                color: $sw-red;
                margin-top: 1.860vw;
            }
        }
    }

    .pool{
        .select-container{
            margin-bottom: 1.860vw;
        }
        .description{
            font-size: 2.791vw;
            line-height: 3.721vw;
            color: $sw-white; 
            margin-bottom: 1.860vw;
        }
    }
    

    .terms-and-conditions{
        .title{
            margin: 5.581vw 0;
        }
        .btn{
            width: 100%;
            position: relative;
            display: flex;
            padding: 2.674vw 1.512vw 2.674vw 10.465vw;
            border-color: $sw-lime;
            margin-top: 4.651vw;

            .icon{
                display: block; 
                width: 10.465vw;

                position: absolute;
                top: -0.233vw;
                left: -0.233vw;
                bottom: -0.233vw;
                background-color: rgba($color: $sw-white, $alpha: 0.5);
                
                -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 25%, 75% 0);
                clip-path: polygon(0 0, 0 100%, 50% 100%, 100% 0%, 100% 0);

                img{
                    width: 3.721vw;
                    height: auto; 
                    display: block;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 2.326vw;
                }
            }
            &.accepted{
                .icon{
                    background-color: $sw-lime;
                }
            }
            .text{
                font-size: 2.558vw;
                font-weight: 400;
                span{ font-weight: 600; }
                line-height: 3.023vw;
                text-transform: uppercase;
            }

        }

    }
    .info{
        .title{
            margin: 5.581vw 0;
        }
        .btn{
            width: 100%;
            position: relative;
            display: flex;
            padding: 2.674vw 1.512vw;
            border-color: $sw-lime;
            margin-top: 4.651vw;

            .text{
                width: 100%;
                font-size: 2.791vw;
                font-weight: 600;
                span{ font-weight: 600; }
                line-height: 3.023vw;
                text-transform: uppercase;
                text-align: center;
            }

        }

    }


    .submit-container{
        display: flex;
        justify-content: center;
        padding: 13.023vw 0 11.628vw;
        .btn{
            border-color: $sw-lime;
        }
    }
}