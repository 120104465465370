// Typography
$rubik: 'Rubik', sans-serif;

// Colors
$sw-white: #FFFFFF;
$sw-black: #000000;
$sw-lime: #D1F669;
$sw-red: #CE2B79;
$sw-red-dark: #8B1739;
$sw-blue-medium: #014A58;
$sw-blue-dark: #011D33;
$sw-petrol-gradient: linear-gradient(180deg, $sw-blue-medium 0%, $sw-blue-dark 100%);
$sw-bg-gradient: linear-gradient(89.71deg, $sw-blue-medium 0.44%, $sw-blue-dark 99.94%);

// Groups Colors
$group-A: #481060;
$group-B: #4FC0B2;
$group-C: #EB1250;
$group-D: #FBB634;
$group-E: #8D1737;
$group-F: #14A554;
$group-G: #1E67AE;
$group-H: #F081B1;