@import "../../styles/base.scss";

.matches{
    padding-bottom: 10vw;
    .user-profile{
        background: $sw-lime;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 3.721vw;

        &--data{
            display: flex;
            align-items: center;
            gap: 3.721vw;
            padding: 1.860vw 3.721vw;
            .user-image{
                display: block;
                width: 9.302vw;
                height: 9.302vw; 
                img{
                    width: 9.302vw;
                    height: 9.302vw;
                    border-radius: 50%; 
                    overflow: hidden;
                    object-fit: cover;
                }
            }
            .nickname{
                font-size: 3.721vw;
                font-weight: 600;
                line-height: 4.419vw;
                color: $sw-blue-medium;
            }
        }
        &--ranking{
            display: flex;
            align-items: center;
            .points{
                font-size: 3.721vw;
                font-weight: 600;
                line-height: 4.419vw;
                color: $sw-blue-medium;
                white-space: nowrap;
                margin-right: -1.860vw;
                span{
                    font-size: 2.558vw;
                }
            }
            .rank{
                display: block;
                height: 13.023vw;
                font-size: 3.721vw;
                font-weight: 600;
                line-height: 4.419vw;
                color: $sw-white;
                white-space: nowrap;
                vertical-align: center;

                background: $sw-red; 
                padding: 4.651vw 3.721vw 4.419vw 9.302vw;
                -webkit-clip-path: polygon(9.302vw 0%, 0 100%, 100% 100%, 100% 100%, 100% 0);
                clip-path: polygon(9.302vw 0%, 0 100%, 100% 100%, 100% 100%, 100% 0);
            }
        }
    }
    .select-container{
        margin-top: 3.721vw;
        margin-bottom: 1.860vw;
    }
    .matches-list{
        .match-card{
            padding: 1.860vw 0;
        }
    }

    .banner-before-competition{

        img{
            width: 100%;
            height: auto;
        }
    }
}