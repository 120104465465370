@import "../../styles/base.scss";

.loading{
    background: $sw-bg-gradient;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    .loader{
        color: $sw-white;
    }
}