@import "../../styles/base.scss";

.pop-up-vote-container{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 11px;
    z-index: 100;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;

    .pop-up-container--inner{
        display: flex;
        flex-direction: column;
        width: 100%;
        // min-height: 500px;
        max-height: 100%;
        overflow-y: scroll;
        background: rgba($color: $sw-blue-dark, $alpha: .95);
        border: 0.349vw solid rgba($sw-white, 0.2);

        padding: 5.581vw;

        .btn-close--container{
            display: flex;
            justify-content: flex-end;
            .btn-close{
                padding: 0;
                margin: 0;
                border: none;
                background: transparent;
                font-size: 0;
                img{
                    width: 5.581vw;
                    height: 5.581vw;
                }
            }
        }

        .step{
            display: none;
            &.visible{
                display: block;
            }
            .title{
                margin: 3.721vw 0;
                font-size: 3.721vw;
                font-weight: 700;
                line-height: 1;
                text-transform: uppercase;
                color: $sw-lime;
            }

            &.visible.country{
                .options{
                    
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-auto-rows: 33.721vw;
                    gap: 1.860vw;

                    .option{
                        background-color: rgba($sw-white, 0.1);
                        display: flex;
                        padding: 3.721vw;
                        gap: 1.860vw;
                        flex-direction: column;
                        align-items: center;

                        &.selected{
                            background-color: rgba($sw-white, 0.2);
                        }

                        .icon{
                            display: block;
                            width: 20.465vw;
                            height: 15.349vw;
                            
                            img{
                                object-fit: contain;
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .name-container{
                            flex-grow: 1;
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            
                            .name{
                                font-size: 3.721vw;
                                font-weight: 700;
                                line-height: 4.419vw;
                                text-align: center;
                                text-transform: uppercase;
                                color: $sw-white;
                            }
                        }
                    }
                }
            }

            &.visible.player{
                .options{
                    
                    display: flex;
                    flex-direction: column;
                    gap: 1.860vw;
                
                    .option{
                        display: flex;
                        gap: 3.721vw;
                        background: rgba($sw-white, 0.1);
                        align-items: center;
                        padding: 3.721vw;

                        &.selected{
                            background: rgba($sw-white, 0.2);
                        }

                        .icon{
                            display: block;
                            width: 5.581vw;
                            height: 5.581vw;
                            img{
                                object-fit: contain;
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .name{
                            font-size: 3.721vw;
                            font-weight: 500;
                            line-height: 1;
                            color: $sw-white;
                        }
                    }
                }
                .btn-vote{
                    display: block;
                    width: 100%;
                    margin-top: 5.581vw;
                    padding: 3.256vw;
                    background-color: #D1F669;
                    border: none;
                    font-weight: 700;
                    font-size: 2.791vw;
                    line-height: 1;
                    text-align: center;
                    text-transform: uppercase;
                    color: #011D33;
                }
            }
            &.visible.final{
                .message{
                    margin: 3.721vw 0;
                    font-size: 3.721vw;
                    font-weight: 600;
                    line-height: 1;
                    text-align: center;
                    color: $sw-lime;
                }
                .btn-back{
                    display: block;
                    border-radius: none;
                    background: rgba($sw-white, 0.2);
                    border: 1.5px solid $sw-lime;
                    padding: 3.721vw;
                    margin: 0 auto;
                    margin-top: 5.581vw;

                    font-size: 2.558vw;
                    font-weight: 700;
                    line-height: 3.023vw;
                    text-transform: uppercase;
                    color: $sw-white;

                }
    
            }
        }






    }
}