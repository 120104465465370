@import "../../styles/base.scss";

.rules{
    padding-bottom: 9.302vw;
    &.popup-open{
        overflow: hidden;
        height: 10vh;
    }
    .terms-list{
        margin-bottom: 1.860vw;
        .step{
            display: block;
            font-size: 3.256vw;
            font-weight: 700;
            line-height: 3.953vw;
            color: $sw-lime;
            margin-bottom: 1.860vw;
        }
        .text{
            display: block;
            font-size: 3.256vw;
            font-weight: 400;
            line-height: 3.953vw;
            color: $sw-white;
            margin-left: 3.721vw;
            text-align: justify;
            white-space: pre-line;
        }
        .child-list{
            list-style-type: lower-alpha; 
            list-style-position: outside; 
            color: $sw-white;
            font-weight: 700;
            padding-left: 8.721vw;
            padding-top: 3.721vw;
            li{
                margin-bottom: 3.721vw;
            }

            .child-text, .name{
                font-size: 3.256vw;
                font-weight: 400;
                line-height: 3.953vw;
                text-align: justify;
            }
            .name{
                font-weight: 700;
                margin-right: 1.860vw;
            }
        }
    }
    .terms-and-conditions{
        .btn{
            width: 100%;
            position: relative;
            display: flex;
            padding: 2.674vw 1.512vw;
            border-color: $sw-lime;
            margin-top: 4.651vw;
            justify-content: center;

            .icon{
                display: block; 
                width: 10.465vw;

                position: absolute;
                top: -0.233vw;
                left: -0.233vw;
                bottom: -0.233vw;
                background-color: $sw-lime;
                
                -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 25%, 75% 0);
                clip-path: polygon(0 0, 0 100%, 50% 100%, 100% 0%, 100% 0);

                img{
                    width: 3.721vw;
                    height: auto; 
                    display: block;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 2.326vw;
                }
            }
            
            .text{
                font-size: 2.558vw;
                font-weight: 400;
                span{ font-weight: 600; }
                line-height: 3.023vw;
                text-transform: uppercase;
            }

        }

    }
}