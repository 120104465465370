@import "../../styles/base.scss";

.prizes{
    padding-bottom: 7.209vw;
    
    .prize-card{
        position: relative;
        display: block;
        margin-top: 3.721vw;

        &--images{
            display: block;
            width: 100%;
            height: auto;
            font-size: 0;
            
            img{
                width: 100%; 
                height: auto;
            }
        }
        &--info{
            padding: 3.721vw;
            display: flex;
            flex-direction: column;
            gap: 1.860vw;
            background-color: rgba($color: $sw-blue-dark, $alpha: .6);
            .title,
            .text,
            .value{
                font-size: 2.791vw;
                font-weight: 400;
                line-height: 1;
                color: $sw-white;
            }
            .title{
                font-weight: 700;
                color: $sw-lime;
            }
            .value{
                color: $sw-lime;
            }
        }
    }
    
}