@import "../../styles/base.scss";

.pop-up-prediction-container{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 11px;
    z-index: 100;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;

    .pop-up-container--inner{
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 500px;
        max-height: 100%;
        overflow-y: scroll;
        background: rgba($color: $sw-blue-dark, $alpha: .95);
        border: 0.349vw solid rgba($sw-white, 0.2);

        padding: 5.581vw;

        .btn-close--container{
            display: flex;
            justify-content: flex-end;
            margin-bottom: 6.047vw;
            .btn-close{
                padding: 0;
                margin: 0;
                border: none;
                background: transparent;
                font-size: 0;
                img{
                    width: 5.581vw;
                    height: 5.581vw;
                }
            }
        }

        .teams{
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            margin-bottom: 4.186vw;
            .team_one{
                display: flex;
                gap: 5.581vw;
                justify-content: flex-start;
                .name{
                    font-size: 3.721vw;
                    font-weight: 700;
                    line-height: 5.581vw;
                    color: $sw-white;
                }
                .flag{
                    display: block;
                    width: 5.581vw;
                    height: 5.581vw;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
            .versus{
                span{
                    font-size: 3.256vw;
                    font-weight: 600;
                    line-height: 5.581vw;
                    color: $sw-white;
                }
            }
            .team_two{
                display: flex;
                gap: 5.581vw;
                justify-content: flex-end;
                .name{
                    font-size: 3.721vw;
                    font-weight: 700;
                    line-height: 5.581vw;
                    color: $sw-white;
                }
                .flag{
                    display: block;
                    width: 5.581vw;
                    height: 5.581vw;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }

        }
        
        .group{
            display: flex;
            font-size: 2.326vw;
            font-weight: 400;
            line-height: 4.186vw;
            text-transform: uppercase;
            color: $sw-white; 
            span{
                font-weight: 700;
            }

            &::before{
                content: '';
                display: block;
                width: 3.721vw;
                height: 3.721vw;
                border-radius: 50%;
                margin-right: 1.860vw;
            }
            &.a::before{ background-color: $group-A; }
            &.b::before{ background-color: $group-B; }
            &.c::before{ background-color: $group-C; }
            &.d::before{ background-color: $group-D; }
            &.e::before{ background-color: $group-E; }
            &.f::before{ background-color: $group-F; }
            &.g::before{ background-color: $group-G; }
            &.h::before{ background-color: $group-H; }
        }

        .divider{
            padding: 3.721vw 0;
            hr{
                height: 0;
                border: 0;
                border-bottom: 1px solid rgba($color: $sw-white, $alpha: .1);
                margin: 0;
            }
        }

        .info{
            display: flex;
            gap: 5.581vw;
            margin-bottom: 4.884vw;
            .time,
            .date,
            .stadium{
                display: flex;
                gap: 1.860vw;
                .icon{
                    height: 5.581vw;
                    display: flex;
                    align-items: center;
                    img{
                        width: 3.721vw;
                        height: 3.721vw;
                    }
                }
                .game-time,
                .game-date{
                    font-size: 2.791vw;
                    font-weight: 300;
                    line-height: 5.581vw;
                    color: $sw-white;
                }
                .game-stadium{
                    font-size: 2.326vw;
                    font-weight: 400;
                    line-height: 5.981vw;
                    text-transform: uppercase;
                    color: $sw-white;
                }
            } 
        }

        .timer{
            font-size: 2.791vw;
            font-weight: 600;
            line-height: 3.256vw;
            color: $sw-lime;
        }

        .prediction{
            padding-top: 10vw;

            &-category{
                margin-bottom: 4.651vw;
                .title{
                    display: block;
                    font-size: 3.721vw;
                    font-weight: 600;
                    line-height: 4.419vw;
                    color: $sw-lime;
                    text-transform:initial;
                    margin-bottom: 3.721vw;
                }
                .winner{
                    display: grid;
                    grid-template-columns: auto min-content auto;
                    gap: 3.721vw;
                    .button{
                        height: 10.698vw;
                        display: flex;
                        justify-content: center;
                        background-color: rgba($color: $sw-white, $alpha: .3);
                        padding: 2.791vw;
                    }
                    li.selected .button{
                        background-color: $sw-lime;

                        .draw{
                            color: $sw-blue-dark;
                        }
                    }

                    .bet{
                        display: block;
                        text-align: center;
                        font-size: 2.791vw;
                        font-weight: 400;
                        line-height: 4.186vw;
                        color: $sw-white;
                        margin-top: 1.860vw;
                        span{
                            font-weight: 600;
                        }
                    }

                    .flag{
                        display: block;
                        width: 5.581vw;
                        height: 5.581vw;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                    .draw{
                        height: 10.698vw;
                        font-size: 4.186vw;
                        font-weight: 500;
                        line-height: 4.884vw;
                        color: $sw-white;
                    }
                    
                }
                .score{
                    ul{
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 3.721vw;

                        li{
                            height: 10.698vw;
                            background-color: rgba($color: $sw-white, $alpha: .3);
                            // padding: 2.791vw 3.721vw;

                            display: grid;
                            grid-template-columns: repeat(3, 1fr);

                            .value{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                
                                font-size: 18px;
                                font-weight: 500;
                                line-height: 21px;
                                color: $sw-white;
                            }

                            button{
                                background-color: transparent;
                                border: none;

                                display: flex;
                                align-items: center;
                                padding: 0 16px;

                                &.plus{
                                    justify-content: flex-start;
                                    img{
                                        width: 12px;
                                        height: auto;
                                        object-fit: contain;
                                    }
                                }
                                &.minus{
                                    justify-content: flex-end;
                                    img{
                                        width: 9px;
                                        height: auto;
                                        object-fit: contain;
                                    }
                                    
                                }

                            }

                        }
                    }
                    
                    .bet{
                        display: block;
                        text-align: center;
                        font-size: 2.791vw;
                        font-weight: 400;
                        line-height: 4.186vw;
                        color: $sw-white;
                        margin-top: 1.860vw;
                        span{
                            font-weight: 600;
                        }
                    }
                }
            }
        }
        .send{
            border-color: $sw-lime; 
            width: min-content;
            white-space: nowrap;
            margin: 0 auto;
        }
    }
}