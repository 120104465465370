@import "../../styles/base.scss";

.select-container{ 
    position: relative;
    width: 100%;

    font-size: 2.791vw;
    font-weight: 700;
    line-height: 3.256vw;
    color: $sw-white;
    text-align: left;
    text-transform: uppercase;

    border: 2px solid $sw-lime;
    margin-bottom: 3.721vw;

    // extend select
    // transition: all .3s ease-in;
    max-height: 10.698vw;
    overflow: hidden;
    &.extended{
        max-height: 100vh;
    }

    .dropdown{
        cursor: pointer;
        display: block;
        padding: 3.256vw;
        background-color: rgba($color: $sw-white, $alpha: .2);

        .icon{
            position: absolute;
            right: -1px;
            top: -1px;
            width: 10vw;
            height: 10vw;
            background-color: $sw-lime;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: 3.721vw;
                height: auto;
                transition: all .3s ease-in-out;
            }
        }
    }
    &.extended{
        .icon img{
            transform: rotateX(180deg);
        }
    }

    .dropdown-menu{
        max-height: 29.30vw;
        overflow-y: scroll;
        li{
            cursor: pointer;
            display: block;
            padding: 3.256vw;
            background-color: transparent;
            display: flex;
            justify-content: space-between;

            &:nth-child(2n){
                background-color: rgba($color: $sw-white, $alpha: .2);
            }
        }

    }


}