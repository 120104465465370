@import "../../styles/base.scss";

.tv{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    padding: 7.018vw 5.263vw 6.140vw;
    background: $sw-blue-medium;
    
    .header{
        .competition-name{
            font-size: 3.947vw;
            font-weight: 700;
            line-height: 4.605vw;
            text-transform: uppercase;
            text-align: center;
            letter-spacing: 0.02em;
            color: $sw-lime;
            margin-bottom: 3.509vw;
        }
        .leaderboard-name{
            font-size: 2.632vw;
            font-weight: 700;
            line-height: 3.070vw;
            text-transform: uppercase;
            text-align: center;
            letter-spacing: 0.02em;
            color: $sw-white;
            margin-bottom: 3.509vw;
        }
    }
    .body{
        flex: 1;
        .clasament-container{
            height: 100%;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(10, auto);
            gap: 1.754vw;
    
            .clasament-item{
                display: block;
                padding: 0 3.509vw;
                background: rgba($color: $sw-white, $alpha: .2);
                display: flex;
                align-items: center;
                gap: 2.632vw;
    
    
                &:first-child{
                    background-color: $sw-red;
                }
                &:nth-child(2){
                    background-color: rgba($color: $sw-lime, $alpha: 0.5);
                }
                &:nth-child(3){
                    background-color: rgba($color: $sw-lime, $alpha: 0.3);
                }
    
    
    
                .icon{
                    width: auto;
                    height: 3.947vw;

                }
    
                .position{ 
                    font-size: 3.070vw;
                    font-weight: 400;
                    line-height: 1;
                    color: $sw-white;
                }
    
                .user-image{
                    display: block;
                    width: 5.263vw;
                    height: 5.263vw;
                    border-radius: 50%;
                    background-color: aliceblue; 
                    overflow: hidden;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
    
                .name{
                    font-size: 3.070vw;
                    font-weight: 600;
                    line-height: 1;
                    color: $sw-white;
                }
    
                .points{
                    flex-grow: 1;
                    text-align: right;
                    font-size: 3.070vw;
                    font-weight: 400;
                    line-height: 1;
                    color: $sw-white;
                    span{
                        font-size: 2.412vw;
                        margin-left: 0.877vw;
                    }
                }
    
            }
        }
    }
}