@import "../../styles/base.scss";

.loading-item{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    overflow: hidden;
    padding-top: 9.302vw;

    .loader{
        color: $sw-white;
    }
}