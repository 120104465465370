@import "./reset.scss";
@import "./variables.scss";

* {
    box-sizing: border-box;
}

body {
    font-family: $rubik;
    overflow-x: hidden;
}

a {
    color: inherit;
    text-decoration: underline;
}

strong {
    font-weight: bold;
}

em {
    font-style: italic;
}

.main-container{
    width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
    padding: 0 7.209vw;
    background: $sw-bg-gradient;
}

.app-title{
    font-size: 3.721vw;
    font-weight: 700;
    line-height: 4.419vw;
    text-align: center;
    text-transform: uppercase;
    color: $sw-white;
    padding-top: 1.860vw;
}

.banner-world-cup{
    font-size: 0;
    padding-top: 3.721vw;
    img{
        width: 100%;
        height: auto;
    }
}

.btn{
    font-size: 2.791vw;
    font-weight: 700;
    line-height: 3.256vw;
    color: $sw-white;
    text-transform: uppercase;
    padding: 2.674vw;
    white-space: nowrap;

    
    border: 0.349vw solid rgba($color: $sw-white, $alpha: .5);
    background: rgba($color: $sw-white, $alpha: .3);

    &.selected{
        background-color: $sw-lime;
        border-color: $sw-lime;
        color: $sw-blue-medium;
    }
}