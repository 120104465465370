@import "../../styles/variables.scss";

.navigation{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.860vw;
    padding: 1.860vw 0 3.721vw;

    &-item{ 
        aspect-ratio: 1;

        a{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            height: 100%;
            font-size: 2.558vw;
            font-weight: 400;
            line-height: 1;
            text-decoration: none;
            text-transform: initial;

            color: rgba($color: $sw-white, $alpha: .8);
            background-color: rgba($color: $sw-white, $alpha: .2);
            border: 0.698vw solid transparent;

            .icon{
                width: auto;
                height: 5.581vw;
                max-width: 5.581vw;
                margin-bottom: 1.860vw;
                
                &-hov{
                    display: none;
                }
            }
            span{
                vertical-align: center;
                font-size: 2.791vw;
                // text-transform: uppercase;
            }

            &.selected{ 
                border-bottom-color: $sw-lime;
                color: rgba($color: $sw-white, $alpha: 1);
                // font-weight: 600;
                
                .icon{
                    display: none;
                    &-hov{
                        display: block;
                    }
                }
            }
        }
    }
}