@import "../../styles/base.scss";

.match-card--container{
    display: flex;
    flex-direction: column;
    padding: 3.721vw;
    background: rgba($color: $sw-white, $alpha: .1);

    .divider{
        padding: 3.721vw 0;
        hr{
            height: 0;
            border: 0;
            border-bottom: 1px solid rgba($color: $sw-white, $alpha: .1);
            margin: 0;
        }
    }
    
    .team-and-date{
        display: grid;
        grid-template-columns: auto 1px min-content;

        .divider{
            background: rgba($color: $sw-white, $alpha: .1);
        }
        .team{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-right: 3.721vw;
            gap: 1.860vw;

            .team_one,
            .team_two{
                display: flex;

                .name{
                    flex-grow: 1;
                    font-size: 3.721vw;
                    font-weight: 700;
                    line-height: 5.581vw;
                    color: $sw-white;
                    text-transform: uppercase;
                }
                .flag{
                    display: inline-block;
                    width: 5.581vw;
                    height: 5.581vw; 
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .score{
                    font-size: 3.721vw;
                    font-weight: 700;
                    line-height: 5.581vw;
                    color: $sw-white;
                    text-transform: uppercase;
                    padding-left: 3.721vw;
                }

            }
            .location{
                display: flex;
                .icon{
                    width: auto;
                    height: 3.721vw;
                    margin: 0 1.977vw 0 0.116vw;
                }
                span{
                    font-size: 2.326vw;
                    font-weight: 400;
                    line-height: 3.721vw;
                    text-transform: uppercase;
                    color: $sw-white;
                }
            }
        }
        .date{
            display: flex;
            justify-content: flex-end;
            padding-left: 3.721vw;
            .date-container--inner{
                display: grid;
                grid-template-columns: min-content auto;
                gap: 2.093vw;
                .icon{
                    height: 5.581vw;
                    display: flex;
                    align-items: center;
                    padding-bottom: 0.465vw;
                    img{
                        width: 3.721vw;
                        height: 3.721vw;
                    }
                }
                .game-time,
                .game-date{
                    font-size: 2.791vw;
                    font-weight: 300;
                    line-height: 5.581vw;
                    color: $sw-white;
                    white-space: nowrap;
                }
                .group-title{
                    display: flex;
                    font-size: 2.326vw;
                    font-weight: 400;
                    line-height: 3.721vw;
                    text-transform: uppercase;
                    color: $sw-white;
                    white-space: nowrap;
                }
                    .group-icon{
                        display: block;
                        width: 3.721vw;
                        height: 3.721vw;
                        border-radius: 50%;
                        margin-right: 1.860vw;
                        span{
                            font-weight: 700;
                        }
                    }

                    .group-icon.a{ background-color: $group-A; }
                    .group-icon.b{ background-color: $group-B; }
                    .group-icon.c{ background-color: $group-C; }
                    .group-icon.d{ background-color: $group-D; }
                    .group-icon.e{ background-color: $group-E; }
                    .group-icon.f{ background-color: $group-F; }
                    .group-icon.g{ background-color: $group-G; }
                    .group-icon.h{ background-color: $group-H; }
            }
        }
    }

    .choices-and-prediction{
        display: flex;
        justify-content: space-between;
        align-items: center;

        .choices{
            display: grid;
            grid-template-columns: repeat(2, auto);
            row-gap: 1.860vw;
            column-gap: 3.721vw;

            .type,
            .choice,
            .points,
            .value{
                font-size: 2.791vw;
                font-weight: 400;
                line-height: 4.186vw;
                text-transform: initial;
                color: #FFFFFF;
            }

            .choice,
            .value{
                &.win{
                    color: $sw-lime;
                }
            }

        }
        .btn{
            position: relative;
            display: flex;
            padding: 2.674vw 1.512vw 2.674vw 10.465vw;
            border-color: $sw-lime;

            .icon{
                display: block; 
                width: 10.465vw;

                position: absolute;
                top: -0.233vw;
                left: -0.233vw;
                bottom: -0.233vw;
                background-color: $sw-lime;
                
                -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 25%, 75% 0);
                clip-path: polygon(0 0, 0 100%, 50% 100%, 100% 0%, 100% 0);

                img{
                    width: auto;
                    height: 3.721vw; 
                    display: block;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 2.326vw;
                }
            }

        }
        .live-game{
            position: relative;
            display: flex;
            align-content: center;
            font-size: 2.791vw;
            font-weight: 700;
            line-height: 3.256vw;
            color: $sw-lime;
            text-transform: uppercase;
            padding: 2.674vw;
            white-space: no-wrap;
            padding: 2.674vw 5.581vw;
        
            border: 0.349vw solid rgba($color: $sw-white, $alpha: .5);
            background: rgba($color: $sw-white, $alpha: .3);
            border-color: $sw-lime;
        }
    }
}