@import "../../styles/variables.scss";

.section-title{
    padding-bottom: 3.721vw;
    font-size: 4.186vw;
    line-height: 3.721vw;
    font-weight: 600;
    color: $sw-white;
    text-decoration: none;
    text-transform: uppercase;
}