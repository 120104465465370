@import "../../styles/base.scss";

.pop-up-info-container{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 2.558vw;
    z-index: 100;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;

    .pop-up-container--inner{
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 500px;
        max-height: 100%;
        overflow-y: scroll;
        background: $sw-bg-gradient;
        border: 0.349vw solid rgba($sw-white, 0.2);

        padding: 5.581vw;

        .btn-close--container{
            display: flex;
            justify-content: flex-end;
            margin-bottom: 6.047vw;
            .btn-close{
                padding: 0;
                margin: 0;
                border: none;
                background: transparent;
                font-size: 0;
                img{
                    width: 5.581vw;
                    height: 5.581vw;
                }
            }
        }
        
        .data{
            .terms-title{
                font-size: 3.721vw;
                font-weight: 600;
                line-height: 4.419vw;
                text-transform: uppercase;
                color: $sw-white;
                margin-bottom: 3.721vw;
            }
            .terms-list{
                margin-bottom: 1.860vw;
                .step{
                    display: block;
                    font-size: 3.256vw;
                    font-weight: 700;
                    line-height: 3.953vw;
                    color: $sw-lime;
                    margin-bottom: 1.860vw;
                    margin-top: 3.721vw;
                }
                .text{
                    display: block;
                    font-size: 3.256vw;
                    font-weight: 400;
                    line-height: 3.953vw;
                    color: $sw-white;
                    margin-left: 3.721vw;
                    text-align: justify;
                    white-space: pre-line;
                }
                .child-list{
                    list-style-type: lower-alpha; 
                    list-style-position: outside; 
                    color: $sw-white;
                    font-weight: 700;
                    padding-left: 8.721vw;
                    padding-top: 3.721vw;
                    li{
                        margin-bottom: 3.721vw;
                    }
        
                    .child-text, .name{
                        font-size: 3.256vw;
                        font-weight: 400;
                        line-height: 3.953vw;
                        text-align: justify;
                    }
                    .name{
                        font-weight: 700;
                        margin-right: 1.860vw;
                    }
                }
            }
        }

    }
}

.register-page .terms-and-conditions .pop-up-terms-container .btn {
    width: min-content;
    display: inline-block;
    padding: 3.256vw 3.721vw;
    font-size: 2.558vw;
    line-height: 3.023vw;
    text-transform: uppercase;
    margin: 3.721vw auto 0; 

}