@import "../../styles/base.scss";

.options-container{
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 1.860vw;

    li{
        font-size: 2.491vw;
        font-weight: 700;
        line-height: 3.256vw;
        color: $sw-white;
        text-transform: uppercase;
        text-align: center;
        padding: 3.256vw;

        background: rgba($color: $sw-white, $alpha: 0.2);
        border: 2px solid rgba($color: $sw-white, $alpha: 0.5);

        &.selected{
            color: $sw-blue-medium;
            background: $sw-lime;
            border-color: $sw-lime;
        }
    }
} 