@import "../../styles/base.scss";

.best-player-card{

    .view-winner{
        padding: 5.581vw 6.977vw;
        margin: 1.860vw -6.977vw 5.581vw;
        background-color: $sw-red-dark;

        .card-title{
            font-size: 3.721vw;
            font-weight: 700;
            line-height: 1;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 1.860vw;
            color: $sw-lime;
        }
        .result{
            padding: 3.721vw;
            background: rgba($color: $sw-white, $alpha: .2);
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 3.721vw;

            .icon{
                display: block;
                width: 5.581vw;
                height: 5.581vw;
                img{
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
            .player{
                font-size: 3.721vw;
                font-weight: 600;
                line-height: 1;
                color: $sw-white;
            }
            .votes{
                flex-grow: 1;
                text-align: right;
                font-weight: 600;
                font-size: 2.558vw;
                line-height: 1;
                color: $sw-white;

                .value{
                    font-size: 3.721vw;
                    margin-right: 1.860vw;
                }
            }
        }
        .btn-vote{
            display: block;
            width: 100%;
            margin-top: 8px;
            padding: 3.256vw;
            background-color: $sw-lime;
            border: none;


            
            font-weight: 700;
            font-size: 2.791vw;
            line-height: 1;
            text-align: center;
            text-transform: uppercase;
            color: $sw-blue-dark;

        }
    }


}