@import "../../styles/base.scss";

.pop-up-terms-container{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 2.558vw;
    z-index: 100;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;

    .pop-up-container--inner{
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 500px;
        max-height: 100%; 
        background: $sw-bg-gradient;
        border: 0.349vw solid rgba($sw-white, 0.2);

        padding: 5.581vw;
        padding-bottom: 23.256vw;

        .btn-close--container{
            display: flex;
            justify-content: flex-end;
            margin-bottom: 6.047vw;
            .btn-close{
                padding: 0;
                margin: 0;
                border: none;
                background: transparent;
                font-size: 0;
                img{
                    width: 5.581vw;
                    height: 5.581vw;
                }
            }
        }
        
        .data{
            padding-left: 4.581vw;
            max-height: 80%;
            overflow-y: scroll;
            
            .terms-title{
                font-size: 3.721vw;
                font-weight: 600;
                line-height: 4.419vw;
                text-transform: uppercase;
                color: $sw-white;
                margin-bottom: 3.721vw;
            }
            .terms-type{
                font-size: 3.721vw;
                font-weight: 400;
                line-height: 4.419vw;
                text-transform: uppercase;
                color: $sw-lime;
                margin-bottom: 3.721vw;
            }
            .data-container{ 
                & > li{
                    padding-top: 3.721vw;
                    font-size: 3.256vw;
                    font-weight: 400;
                    line-height: 3.721vw; 
                    color: $sw-white;
                    margin-bottom: 1.860vw;
                    text-align: justify;
                    white-space: pre-line;

                    list-style-type:decimal;

                    & > ol{
                        // margin-top: 8px;
                        margin-left: 4.256vw;

                        & > li{
                            padding-top: 1.860vw;
                            font-size: 3.256vw;
                            font-weight: 400;
                            line-height: 3.721vw; 
                            color: $sw-white;
                            // margin-bottom: 1.860vw;
                            text-align: justify;
                            white-space: pre-line;
        
                            list-style-type:decimal;

                            & > ol{
                                // margin-top: 8px;
                                margin-left: 3.256vw;
        
                                & > li{
                                    padding-top: 1.860vw;
                                    font-size: 3.256vw;
                                    font-weight: 400;
                                    line-height: 3.721vw; 
                                    color: $sw-white;
                                    // margin-bottom: 1.860vw;
                                    text-align: justify;
                                    white-space: pre-line;
                
                                    list-style-type:lower-alpha;
                                }
                            }
                        }
                    }
                    
                }
            }
        }

    }
}

.register-page .terms-and-conditions .pop-up-terms-container .btn {
    width: min-content;
    display: inline-block;
    padding: 3.256vw 3.721vw;
    font-size: 2.558vw;
    line-height: 3.023vw;
    text-transform: uppercase;
    margin: 3.721vw auto 0; 

    position: absolute;
    bottom: 5.581vw;
    left: 50%;
    transform: translateX(-50%);

}
